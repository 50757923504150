<template>
    <b-row>
        <b-col lg="12">
            <trading-view />
        </b-col>

        <b-col lg="12">
            <statistics-group v-if="$havePermission('DETAILS_TRADES')"/>
        </b-col>

        <b-col lg="12">
            <apex-line-chart v-if="false"/>
        </b-col>

        <b-col
                lg="3"
                sm="6"
        >
            <statistic-card-with-area-chart
                    v-if="$havePermission('LIST_USERS')"
                    icon="UsersIcon"
                    :statistic="footerChartData.users.count"
                    statistic-title="کاربران سایت"
            />
        </b-col>
        <b-col
                lg="3"
                sm="6"
        >
            <statistic-card-with-area-chart
                    v-if="$havePermission('LIST_WITHDRAW')"
                    icon="CreditCardIcon"
                    color="success"
                    :statistic="footerChartData.requests.count"
                    statistic-title="درخواست های ثبت شده"
                    :chart-data="footerChartData.requests.series"
            />
        </b-col>
        <b-col
                lg="3"
                sm="6"
        >
            <statistic-card-with-area-chart
                    v-if="$havePermission('LIST_ORDERS')"
                    icon="ShoppingCartIcon"
                    color="danger"
                    :statistic="footerChartData.orders.count"
                    statistic-title="سفارش های ثبت شده"
                    :chart-data="footerChartData.orders.series"
            />
        </b-col>
        <b-col
                lg="3"
                sm="6"
        >
            <statistic-card-with-area-chart
                    v-if="$havePermission('LIST_TRADES')"
                    icon="PackageIcon"
                    color="warning"
                    :statistic="footerChartData.trade.count"
                    statistic-title="معاملات انجام شده"
                    :chart-data="footerChartData.trade.series"
            />
        </b-col>
    </b-row>

</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'
import StatisticsGroup from "@/views/Markets/StatisticsGroupVol";
import TradingView from "@/views/Markets/TradingView";
import ApexLineChart from "@/views/Dashboard/ApexLineChart";
import StatisticCardWithAreaChart from "@/views/Dashboard/StatisticCardWithAreaChart";

export default {
    name: "Statistics",
    components: {
        StatisticCardWithAreaChart,
        ApexLineChart,
        TradingView,
        StatisticsGroup,
        BRow,
        BCol,
    },
    data() {
        return {
            // Area charts
            subscribersGained: {},
            revenueGenerated: {},

            // Line Charts
            siteTraffic: {},
            activeUsers: {},
            newsletter: {},
            footerChartData: {
                trade: {
                    count: '',
                    series: [{name: " ", data: [0]}]
                },
                orders: {
                    count: '',
                    series: [{name: " ", data: [0]}]
                },
                users: {
                    count: '',
                    series: [{name: " ", data: [0]}]
                },
                requests: {
                    count: '',
                    series: [{name: " ", data: [0]}]
                }
            }
        }
    },
    methods: {},
    async mounted() {
        if (this.$havePermission('LIST_USERS')) {
            this.$axios('/users/count')
                .then(i => {
                    this.footerChartData.users.count = this.$numFormatter(i.data.data.count)
                })
        }
        if (this.$havePermission('LIST_WITHDRAW')) {
            this.$axios('/transactions/count')
                .then(i => {
                    this.footerChartData.requests.count = this.$numFormatter(i.data.data.count)
                })
        }
        if (this.$havePermission('LIST_ORDERS')) {
            this.$axios('/orders/count')
                .then(i => {
                    this.footerChartData.orders.count = this.$numFormatter(i.data.data.count)
                })
        }
        if (this.$havePermission('LIST_TRADES')) {
            this.$axios('/trades/count')
                .then(i => {
                    this.footerChartData.trade.count = this.$numFormatter(i.data.data.count)
                })
        }
    }
}
</script>

<style scoped>

</style>
